import styled from '@emotion/styled'
import { FlexBox } from 'app/components/FlexBox'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export default memo(function Error404() {
  return (
    <Container>
      <Wrapper>
        <Logo to="/" />
        <Title>Page Not Found</Title>
        <Text>Oops, we couldn't find this page!</Text>
        <Anchor to="/">back to Home</Anchor>
      </Wrapper>
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.primary};
  position: fixed;
  top: 0;
  left: 0;
`

const Wrapper = styled.div`
  line-height: 4rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3rem;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
`

const Logo = styled(Link)`
  display: inline-block;
  width: 199px;
  height: 33px;
  background: url('/logo.png') center no-repeat;
  background-size: cover;
  margin-bottom: 4rem;

  @media (max-width: 1023px) {
    width: 100px;
    height: 16px;
  }
`

const Title = styled.h1``

const Text = styled.div``

const Anchor = styled(Link)`
  display: inline-block;
  line-height: 1.5rem;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.variants.base};
  border: 1px solid ${({ theme }) => theme.colors.variants.base};
  margin-top: 3rem;
  padding: 0.875rem 2rem;
  text-transform: uppercase;
`
